import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';

import {
  BadgeGroupAlert,
  Button,
  FFTextInput,
  GBTypography,
} from '@leapfinance/geebee-component-library';
import { LinearProgress } from '@mui/material';
import createDecorator from 'final-form-focus';
import { makeValidate } from 'mui-rff';
import { useRouter } from 'next/router';

// import { skipToken } from '@reduxjs/toolkit/query/react'
import SentryError from '@/lib/Sentry';
import { trackSignIn, trackSignUp } from '@/segment/eventTracker';
import {
  useLazyGetCspDetailsFromDomainQuery,
  useLoginMutation,
} from '@/services/base/auth';
import { LoginStatus } from '@/types/segmentEvents';
import {
  GBLocalStorageUtil,
  localStorageKeys,
} from '@/utils/GBLocalStorageUtil';
import {
  analyzeUserLogin,
  getBaseUrl,
  getSlugAfterHostname,
  LOGIN_ERROR_ENUM,
  LOGIN_SCREEN_ERROR_MESSAGES,
} from '@/utils/loginUtils';
import { loginSchema } from '@/validations';

import ForgotPassword from './ForgotPassword';

const validate = makeValidate(loginSchema);

const focusOnError: any = createDecorator();

export enum CredentialScreen {
  'LOGIN',
  'FORGOTPASSWORD',
}

const errorsToExcludeForSentry = [
  LOGIN_ERROR_ENUM.INVALID_CREDENTIALS,
  LOGIN_ERROR_ENUM.INVALID_DOMAIN,
];

const LoginSignUp = () => {
  const [errorFound, setErrorFound] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<
    string | LOGIN_SCREEN_ERROR_MESSAGES
  >(``);
  const [loading, setLoading] = useState<boolean>(false);
  const router = useRouter();
  const [currentScreen, setCurrentScreen] = useState(CredentialScreen.LOGIN);
  const [loginUserTrigger] = useLoginMutation();
  const [triggerGetCspDetails, { data }] =
    useLazyGetCspDetailsFromDomainQuery();

  useEffect(() => {
    const url = new URL(window?.location?.origin);
    const domain = url?.hostname?.split(`.`)?.[0];
    triggerGetCspDetails({
      'csp-domain-from-url': domain,
    });
  }, []);

  const handleStudentRedirection = () => {
    const url = new URL(window?.location?.origin);
    const domain = url?.hostname?.split(`.`)?.[0];
    if (domain === `unisetu`) {
      window.location.replace(`https://studyoverseas.ai/test-prep/mock-test`);
    } else {
      window.location.replace(
        `https://${domain}.studyoverseas.ai/test-prep/mock-test`,
      );
    }
  };

  const handleFormSubmit = async (values: any) => {
    const callbackUrl = router.asPath.split(`callbackUrl=`)[1] as string;

    setLoading(true);
    setErrorFound(false);
    setErrorMsg(``);
    GBLocalStorageUtil.handleRemoveLocalStorageData(localStorageKeys.TOKEN);

    const loginPayload = {
      userName: values?.username,
      password: values?.password,
    };

    try {
      let isProd;
      if (callbackUrl) {
        const parsedURL = new URL(callbackUrl);
        isProd = parsedURL.hostname.includes(`unisetu.com`);
      }
      const {
        defaultPassword,
        loggedInUserPermissions,
        isNewStudentPortalEnabled,
      } = await loginUserTrigger(loginPayload).unwrap();

      if (defaultPassword) {
        trackSignUp(LoginStatus.SUCCESS);
      } else {
        trackSignIn(LoginStatus.SUCCESS);
      }

      const messageToHoldLogin = analyzeUserLogin({
        loggedInUserPermissions,
        isNewStudentPortalEnabled,
      });
      if (messageToHoldLogin) {
        setErrorFound(true);
        setErrorMsg(messageToHoldLogin);
        return;
      }

      // if default password is true, redirect to change password route -> new user
      // else redirect to home page

      const redirectUrl = defaultPassword
        ? `${getBaseUrl(window.location.origin)}/changepwd?uName=${
            values?.username
          }`
        : callbackUrl
        ? `${getBaseUrl(callbackUrl)}${
            isProd ? `/${getSlugAfterHostname(callbackUrl)}` : ``
          }`
        : `${getBaseUrl(window.location.origin)}/`;

      window.location.replace(redirectUrl);
    } catch (error: any) {
      if (!errorsToExcludeForSentry.includes(error.data.message)) {
        new SentryError(`Login/Signup Failed`, error);
      }

      trackSignIn(LoginStatus.FAILED);
      if (error.status && (error.status === 401 || error.status === 400)) {
        setErrorFound(true);
        setErrorMsg(error.data.message);
      } else {
        console.log(error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col justify-center h-full">
      {currentScreen === CredentialScreen.LOGIN ? (
        <Form
          onSubmit={handleFormSubmit}
          validate={validate}
          decorators={[focusOnError]}
          render={({ handleSubmit }) => (
            <form
              onSubmit={handleSubmit}
              noValidate
              className={`flex px-6 flex-col h-[90%] justify-center items-center gap-6`}
            >
              <div className="flex flex-col gap-4 items-center">
                <GBTypography variant="h2Semibold">
                  Welcome to Unisetu
                </GBTypography>
                {errorFound &&
                errorMsg !==
                  LOGIN_SCREEN_ERROR_MESSAGES.INVALID_STUDENT_USER_ON_UNISETU ? (
                  <div className="flex justify-center">
                    <BadgeGroupAlert
                      variant="warning"
                      size="mobile"
                      secondaryBadgeTitle="Invalid Credentials"
                      title="It seems the credentials you entered are incorrect. Please contact your point of contact (POC) to obtain the correct login details."
                    />
                  </div>
                ) : null}
              </div>
              <div className="flex flex-col gap-3 items-center w-full">
                <FFTextInput
                  fieldProps={{ name: `username` }}
                  componentProps={{
                    required: true,
                    placeholder: `Enter Username`,
                    label: `Username`,
                    className: `bg-white`,
                    size: `small`,
                    onKeyDown: (event) => {
                      if (event.code === `Enter`) {
                        handleSubmit();
                      }
                    },
                  }}
                />
                <FFTextInput
                  fieldProps={{ name: `password` }}
                  componentProps={{
                    required: true,
                    placeholder: `Enter Password`,
                    label: `Password`,
                    type: `password`,
                    className: `bg-white`,
                    size: `small`,
                    onKeyDown: (event) => {
                      if (event.code === `Enter`) {
                        handleSubmit();
                      }
                    },
                  }}
                />
              </div>
              <div className="flex flex-col gap-4 items-center justify-center w-full">
                <Button
                  variant="primary"
                  size="large"
                  className="w-[300px]"
                  onClick={handleSubmit}
                >
                  Login
                </Button>
                <GBTypography
                  variant="link2Regular"
                  className="text-primary-500 flex items-center justify-center cursor-pointer"
                  onClick={() => {
                    setCurrentScreen(CredentialScreen.FORGOTPASSWORD);
                  }}
                >
                  Forgot password?
                </GBTypography>
                {data?.data?.newStudentPortalEnabled ||
                errorMsg ===
                  LOGIN_SCREEN_ERROR_MESSAGES.INVALID_STUDENT_USER_ON_UNISETU ? (
                  <div className="items-center flex gap-2 justify-center">
                    <GBTypography
                      variant="body3"
                      className="text-gbTokens-grey-A900"
                    >
                      Are you a student?
                    </GBTypography>
                    <Button
                      variant="link"
                      size="large"
                      className="p-0 h-fit"
                      onClick={handleStudentRedirection}
                    >
                      Login Here
                    </Button>
                  </div>
                ) : null}
                {loading && (
                  <div className="w-full">
                    <LinearProgress color="primary" />
                  </div>
                )}
              </div>
            </form>
          )}
        />
      ) : (
        <ForgotPassword
          onBack={() => {
            setErrorFound(false);
            setCurrentScreen(CredentialScreen.LOGIN);
          }}
        />
      )}
    </div>
  );
};

export default LoginSignUp;
