import { cmsBaseApiWithTags } from './base/cmsRouteApi';

const cmsApi = cmsBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getPillarPage: builder.query<
      any,
      {
        id: number;
      }
    >({
      query: ({ id }) => `/pillar-pages/${id}`,
    }),
    getPillarPages: builder.query<any, any>({
      query: () => `/pillar-pages`,
    }),
  }),
});

export const { useGetPillarPageQuery, useGetPillarPagesQuery } = cmsApi;
