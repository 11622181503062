function getVideoComponent(innerHtml: string) {
  const wrapperDiv = document.createElement(`div`);

  wrapperDiv.style.position = `relative`;
  wrapperDiv.style.height = `0`;
  wrapperDiv.style.overflow = `hidden`;

  // Apply styles for 16x9 Aspect Ratio
  wrapperDiv.style.paddingBottom = `56.25%`;

  const iframeVideo = document.createElement(`div`);
  iframeVideo.style.position = `absolute`;
  iframeVideo.style.top = `0`;
  iframeVideo.style.left = `0`;
  iframeVideo.style.width = `100%`;
  iframeVideo.style.height = `100%`;
  iframeVideo.innerHTML = innerHtml;

  // Center the video within the container
  iframeVideo.style.display = `flex`;
  iframeVideo.style.justifyContent = `center`;
  iframeVideo.style.alignItems = `center`;

  wrapperDiv.appendChild(iframeVideo);

  return wrapperDiv;
}

function getImageComponent(url: string, isMobile: boolean) {
  const img = document.createElement(`img`);
  img.src = url;
  img.setAttribute(`width`, isMobile ? `${window.innerWidth - 50}` : `95%`);
  // img.height = isMobile ? 200 : 372;
  img.setAttribute(`height`, `auto`); // to maintain aspect ratio
  return img;
}

export { getImageComponent, getVideoComponent };
