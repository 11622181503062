import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';

import { isFalsy } from '@leapfinance/frontend-commons';
import CircularProgress from '@mui/material/CircularProgress';
import Image from 'next/image';

import { useGetPillarPageQuery } from '@/services/cms';
import {
  getImageComponent,
  getVideoComponent,
} from '@/utils/components/Onboarding';

function Onboarding() {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isClientError, setIsClientError] = useState(false);

  const { data, isError, isLoading } = useGetPillarPageQuery({
    id: Number(process.env.NEXT_PUBLIC_CMS_PILLAR_PAGE_ID),
  });

  const productOnboardingPillarPage = data;

  useEffect(() => {
    if (isFalsy(productOnboardingPillarPage)) {
      return;
    }
    try {
      if (containerRef.current && !containerRef.current.shadowRoot) {
        containerRef?.current?.attachShadow({
          mode: `open`,
        }) || ({} as any);
      }

      // resets shadow dom data whenever data changes
      if (containerRef?.current?.shadowRoot?.innerHTML) {
        containerRef.current.shadowRoot.innerHTML = ``;
      }

      if (productOnboardingPillarPage) {
        const headerDiv = document.createElement(`div`);
        headerDiv.innerHTML = productOnboardingPillarPage.page_overview;
        containerRef?.current?.shadowRoot?.appendChild(headerDiv);
      }

      if (
        productOnboardingPillarPage &&
        productOnboardingPillarPage?.main_blocks?.[0]?.slider_card?.image?.url
      ) {
        containerRef?.current?.shadowRoot?.appendChild(
          getImageComponent(
            productOnboardingPillarPage.main_blocks[0].slider_card.image.url,
            isMobile,
          ),
        );
      } else {
        if (
          productOnboardingPillarPage &&
          productOnboardingPillarPage?.video_component?.iframe_link
        ) {
          const wrapperDiv = getVideoComponent(
            productOnboardingPillarPage.video_component.iframe_link,
          );
          containerRef?.current?.shadowRoot?.appendChild(wrapperDiv);
        }
      }

      if (
        productOnboardingPillarPage &&
        productOnboardingPillarPage?.main_blocks?.[0]?.rich_text
      ) {
        const richTextParaDiv = document.createElement(`div`);
        richTextParaDiv.innerHTML =
          productOnboardingPillarPage.main_blocks[0].rich_text;
        containerRef?.current?.shadowRoot?.appendChild(richTextParaDiv);
      }

      if (
        productOnboardingPillarPage &&
        productOnboardingPillarPage?.main_blocks?.[0]?.slider_card?.image?.url
      ) {
        if (
          productOnboardingPillarPage &&
          productOnboardingPillarPage?.video_component?.iframe_link
        ) {
          const wrapperDiv = getVideoComponent(
            productOnboardingPillarPage.video_component.iframe_link,
          );
          containerRef?.current?.shadowRoot?.appendChild(wrapperDiv);
        }
      }
    } catch (err) {
      setIsClientError(true);
      console.error(`error::`, err);
    }
  }, [isMobile, productOnboardingPillarPage]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center">
        <CircularProgress />
      </div>
    );
  }

  if (isError || isClientError || isFalsy(productOnboardingPillarPage)) {
    return (
      <Image
        priority={true}
        src={`/svgs/login-page-banner.svg`}
        layout="fill"
        alt="CSP logo"
        className="object-contain"
      />
    );
  }

  return (
    <div>
      {/* <div className="flex flex-col justify-center items-center pt-6 md:hidden">
        <Image
          src="/geebee-logo.svg"
          className="object-cover"
          width={132}
          height={37}
          alt="Logo"
        />
      </div> */}
      {/* shadow dom attached inside this to render cms content so styling with tailwind doesnt conflict */}
      <div ref={containerRef}></div>
    </div>
  );
}

export default Onboarding;
